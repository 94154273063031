<script lang="ts" setup>
// Imports
import { useField } from 'vee-validate';
import type { IconName } from '../Icon.vue';

// Props & Emits & Refs
const modelValue = defineModel();
const props = withDefaults(
  defineProps<{
    type?: string;
    name: string;
    hideDetails?: boolean;
    label?: string;
    placeholder?: string;
    autocomplete?: string;
    required?: boolean;
    disabled?: boolean;
    rightIcon?: IconName;
    rightText?: string;
    min?: number;
    max?: number;
  }>(),
  {
    type: 'text',
  },
);

// Data
const { value, errorMessage } = useField(props.name);
const input = ref(null);
const inputContainer = ref(null);

watchEffect(() => (modelValue.value = value.value));
</script>
<template>
  <div ref="inputContainer" class="w-full" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label
      v-if="label"
      :class="[errorMessage ? 'text-red-300' : 'text-gray-600', disabled ? 'text-gray-400' : '']"
      class="font-medium text-xs mb-[6px] block text-left"
    >
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div class="w-full relative">
      <input
        :id="name"
        ref="input"
        v-model="value"
        :name="name"
        aria-autocomplete="both"
        :autocomplete="autocomplete"
        :disabled="disabled"
        required
        :class="[
          errorMessage
            ? 'border-red-600 focus:border-red-600 focus:ring-red-100'
            : 'border-gray-200 focus:border-primary-500 hover:ring-[1px] focus:ring-green-100 hover:border-gray-300 hover:ring-gray-100',
          disabled ? '!border-gray-100 text-gray-300 cursor-not-allowed' : '',
        ]"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        class="p-[12px] px-2 h-[32px] text-sm autofill:bg-white bg-white outline-none focus:ring-[2px] ring-offset-0 border-[1px] w-full text-[14px] text-gray-700 rounded-md"
        :type="type"
      />
      <ui-icon v-if="rightIcon" :name="rightIcon" class="stroke-gray-400 absolute w-4 top-[4px] right-[8px]" />
      <span v-if="rightText" class="text-gray-400 absolute top-[8px] right-[12px] text-xs">{{ rightText }}</span>
      <span class="absolute right-0 top-[34px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
